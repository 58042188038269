








import { Component, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/checkout/PageLayout.vue';
import CheckoutHeader from '@/components/checkout/layout/navbar/CheckoutHeader.vue';
import { NavigationGuardNext, Route } from 'vue-router';

const CheckoutUserLogin = () =>
  import(/* webpackChunkName: "screens.checkout.step-login" */ '@/components/screens/checkout/CheckoutStepLogin.vue');

@Component({
  components: {
    CheckoutUserLogin,
    PageLayout,
    CheckoutHeader
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Login>) {
    next((vm: Login) => {
      vm.from = from;
    });
  }
})
export default class Login extends Vue {
  private from: Route | null = null;
}
