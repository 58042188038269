




import { Component, Vue } from 'vue-property-decorator';

import { LoginRoute, ReservationRoute } from '@/router/routes';
import UserService from '@/services/user.service';

@Component({})
export default class RegistrationConfirm extends Vue {
  get registerToken(): string {
    return this.$route.params.registerToken.toString() ?? '';
  }

  get queryFrom(): string {
    return this.$route.query.from?.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to?.toString() ?? '';
  }

  get car(): string {
    return this.$route.query.carId?.toString() ?? '';
  }

  async mounted() {
    try {
      await UserService.confirmRegistration(this.registerToken);
      this.$toast.success('Registrace potvrzena. Teď už se jen musíte přihlásit.');
      if (this.queryFrom && this.queryTo && this.car) {
        await this.$router.push({
          query: {
            from: this.queryFrom,
            to: this.queryTo,
            car: this.car,
            login: '1'
          },
          name: ReservationRoute
        });
      } else {
        this.redirectToLogin();
      }
    } catch (e) {
      this.$toast.error((e as Error).message);
      this.redirectToLogin();
    }
  }

  private redirectToLogin() {
    this.$router.push({
      name: LoginRoute,
      query: {
        login: '1'
      }
    });
  }
}
